<template>
<div class="flex-col">
    <div class="text-center py-5 text-white">
        <img class="mx-auto" src="./assets/spbuilder-500.png" />
        <div class="py-2">
            <p>Build a social portfolio using historical data to balance your risk/reward</p>
        </div>
    </div>

    <div class="flex">
        <div class="xl:w-1/6 w-0"></div>
        <div id="app" class="flex-col xl:w-4/6 w-full px-3 pb-10">
            <div class="bg-white flex md:flex-row flex-col py-3 ">
                <div class="flex-1 px-3 border-r border-gray-300">
                    <h2 class="font-semibold text-lg">Set timeframe</h2>
                    <p>Set the timeframe for the portfolio</p>
                    <div class="py-4 text-lg flex">
                        <input class="p-3 border rounded w-3/6" v-if="IsMonths" type="number" v-model.number="months" />
                        <input class="p-3 border rounded w-3/6" v-if="IsYears" type="number" v-model.number="years" />
                        <select v-model="timeframe" class="ml-4 p-3 border rounded w-3/6">
                            <option selected value="months">Months</option>
                            <option value="years">Years</option>
                        </select>
                    </div>
                </div>
                <div class="flex-1 flex-col px-3 pt-3 sm:pt-0">
                    <div>
                        <h2 class="font-semibold text-lg">Add Copies</h2>
                        <p>Add copies to the builder by entering their Etoro username</p>
                    </div>
                    <div class="flex flex-row pb-2 pt-3">
                        <div class="flex-1 pr-2">
                            <label for="username" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Username</label>
                            <input class="border w-full p-3 rounded" id="username" type="text" v-model="newPerson" />
                            <p v-if="personNotFound" class="text-red-400">Cannot find user</p>
                        </div>

                        <div class="flex-1 pl-2">
                            <label for="investment" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Investment $</label>
                            <input class="border w-full p-3 rounded" id="investment" v-model.number="newInvestment" />
                        </div>
                    </div>
                    <div class="pb-2">
                        <select class="border w-full p-3 rounded" id="investment-method" v-model="investmentMethod">
                            <option value="start">One Time Investment</option>
                            <option v-if="timeframe === 'months'" value="pertime">Monthly Investment</option>
                            <option v-if="timeframe === 'years'" value="pertime">Yearly Investment</option>
                        </select>
                    </div>
                    <div>
                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded sm:w-auto w-full" v-on:click="AddPerson">Copy</button>
                    </div>
                </div>
                <div class="flex-1 flex-col px-3 border-l border-gray-300 pt-3 sm:pt-0">
                    <h2 class="font-semibold text-lg">Save and share</h2>
                    <p>Save your built portfolio and share it with a sharable link</p>
                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 my-3 px-4 rounded sm:w-auto w-full" v-on:click="SavePortfolio">Save</button>
                    <div>
                        <div class="lds-facebook" v-if="generatingShareLink"><div></div><div></div><div></div></div>
                    </div>
                    <div class="select-all p-3 border rounded" v-if="shareId !== ''">https://app.spbuilder.io/?portfolio={{shareId}}</div>
                </div>
            </div>

            <div v-if="peopleData.length > 0" class="flex-col overflow-x-auto mt-3">
                <div class="flex py-4 bg-white">
                    <div class="mx-auto">
                        <div class="px-2 inline-block">
                            <input v-model="showPercentage" type="checkbox" />
                            <label>Percentage</label>
                        </div>
                        <div class="px-2 inline-block">
                            <input v-model="showChange" type="checkbox" />
                            <label>Change</label>
                        </div>
                        <div class="px-2 inline-block">
                            <input v-model="showTotal" type="checkbox" />
                            <label>Total</label>
                        </div>
                    </div>
                </div>
                <table class="w-full">
                    <tr v-if="IsMonths" class="text-center bg-white">
                        <td class="border-b"></td>
                        <td v-for="month in GetMonths()" :key="month" class="border-b text-center font-semibold">
                            {{month}}
                        </td>
                    </tr>
                    <tr v-if="IsYears" class="bg-white">
                        <td class="border-b"></td>
                        <td v-for="year in GetYears()" :key="year" class="border-b text-center font-semibold">
                            {{year}}
                        </td>
                    </tr>
                    <tr v-for="data in peopleData" :key="data.username" class="border-b bg-white">
                        <td class="flex-row my-3 pl-3">
                            <div class="text-lg font-semibold">{{data.username}}</div>
                            <div class="flex">
                                <div class="h-24 w-24">
                                    <img class="h-24 w-24 object-cover rounded" v-bind:src="data.imageUrl" :alt="data.username">
                                </div>
                                <div class="pl-3">
                                    <div class="pb-2">{{WorkOutInvestmentPercentage(data.username)}}% Invested</div>
                                    <div>
                                        <input type="number" v-model.number="data.investment" class="border p-1 rounded" />
                                    </div>
                                    <select v-model="data.investmentMethod" class="border p-1 rounded my-1 w-full">
                                        <option value="start">One Time</option>
                                        <option v-if="timeframe === 'months'" value="pertime">Monthly</option>
                                        <option v-if="timeframe === 'years'" value="pertime">Yearly</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <a class="text-red-400 no-underline hover:underline cursor-pointer" v-on:click="RemovePerson(data.username)">Remove</a>
                            </div>
                        </td>
                        <td v-for="investment in WorkOutUserInvestments(data.username)" :key="investment.balance" class="px-2 text-center">
                            <div v-if="showPercentage">(<span v-bind:class="{'text-green-400' : investment.gain > 0, 'text-red-400' : investment.gain < 0}">{{investment.gain}}%</span>)</div>
                            <div v-if="showChange"><span v-bind:class="{'text-green-400' : investment.gain > 0, 'text-red-400' : investment.gain < 0}">${{investment.change.toFixed(2)}}</span></div>
                            <div v-if="showTotal" class="mt-3">${{investment.balance.toFixed(2)}}</div>
                        </td>
                    </tr>
                    <tr class="bg-white">
                        <td class="text-lg font-semibold">
                        </td>
                        <td v-for="investment in WorkOutCombinedInvestments()" :key="investment.balance" class="text-center py-5">
                            <div v-if="showPercentage">(<span v-bind:class="{'text-green-400' : investment.gain > 0, 'text-red-400' : investment.gain < 0}">{{investment.gain.toFixed(2)}}%</span>)</div>
                            <div v-if="showChange"><span v-bind:class="{'text-green-400' : investment.gain > 0, 'text-red-400' : investment.gain < 0}">${{investment.change.toFixed(2)}}</span></div>
                            <div v-if="showTotal" class="mt-3">${{investment.balance.toFixed(2)}}</div>
                        </td>
                    </tr>
                                        
                    <tr>
                        <td class="py-2"></td>
                    </tr>

                    <tr class="bg-white">
                        <td v-bind:colspan="GetTableColumnCount()" class="p-3">
                            <div>
                                <div class="font-semibold text-left">Compare your portfolio to other assests.</div>
                                <div class="text-base text-left">For example: SPY (tracks the S&P 500 index) or QQQ (tracks the Nasdaq 100 index)</div>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="IsMonths" class="text-center bg-white">
                        <td class="border-b"></td>
                        <td v-for="month in GetMonths()" :key="month" class="border-b text-center font-semibold">
                            {{month}}
                        </td>
                    </tr>
                    <tr v-if="IsYears" class="bg-white">
                        <td class="border-b"></td>
                        <td v-for="year in GetYears()" :key="year" class="border-b text-center font-semibold">
                            {{year}}
                        </td>
                    </tr>
                    <tr v-for="data in comparisonData" :key="data.symbol" class="bg-white">
                        <td class="text-lg font-semibold border-b pl-3 text-center">
                            {{data.symbol}}
                        </td>
                        <td v-for="investment in WorkOutComparisonInvestments(data.symbol)" :key="investment.balance" class="px-2 py-5 border-b text-center">
                            <div v-if="showPercentage">(<span v-bind:class="{'text-green-400' : investment.gain > 0, 'text-red-400' : investment.gain < 0}">{{investment.gain}}%</span>)</div>
                            <div v-if="showChange"><span v-bind:class="{'text-green-400' : investment.gain > 0, 'text-red-400' : investment.gain < 0}">${{investment.change.toFixed(2)}}</span></div>
                            <div v-if="showTotal" class="mt-3">${{investment.balance.toFixed(2)}}</div>
                        </td>
                    </tr>
                    <tr class="bg-white">
                        <td v-bind:colspan="GetTableColumnCount()" class="p-3">
                            <div class="inline">
                                <label for="symbol" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Add Symbol</label>
                                <input class="border py-2 px-4 rounded" id="symbol" v-model="newSymbol" />
                            </div>
                            <div class="inline">
                                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded sm:w-auto w-full" v-on:click="AddSymbol">Add</button>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td class="py-2"></td>
                    </tr>

                    <tr>
                        <td v-bind:colspan="GetTableColumnCount()">
                            <table class="w-full bg-white text-left">
                                <tr class="border-b">
                                    <th></th>
                                    <th class="py-3 pl-2">Popular investors</th>
                                    <th v-for="data in comparisonData" :key="data.symbol">{{data.symbol}}</th>
                                </tr>
                                <tr class="border-b">
                                    <th v-if="IsMonths" class="py-3 pl-2">Avg Month</th>
                                    <th v-if="IsYears" class="py-3 pl-2">Avg Year</th>
                                    <td>{{WorkOutAverageReturn().toFixed(2)}}%</td>
                                    <td v-for="data in comparisonData" :key="data.symbol">{{GetAvgComparisonReturn(data.symbol).toFixed(2)}}%</td>
                                </tr>
                                <tr class="border-b">
                                    <th v-if="IsMonths" class="py-3 pl-2">Best Month</th>
                                    <th v-if="IsYears" class="py-3 pl-2">Best Year</th>
                                    <td>{{WorkOutBestReturn().toFixed(2)}}%</td>
                                    <td v-for="data in comparisonData" :key="data.symbol">{{GetBestComparisonReturn(data.symbol).toFixed(2)}}%</td>
                                </tr>
                                <tr class="border-b">
                                    <th v-if="IsMonths" class="py-3 pl-2">Worst Month</th>
                                    <th v-if="IsYears" class="py-3 pl-2">Worst Year</th>
                                    <td>{{WorkOutWorstReturn().toFixed(2)}}%</td>
                                    <td v-for="data in comparisonData" :key="data.symbol">{{GetWorstComparisonReturn(data.symbol).toFixed(2)}}%</td>
                                </tr>
                                <tr class="border-b">
                                    <th class="py-3 pl-2">End Balance</th>
                                    <td>${{TotalEndBalance().toFixed(2)}}</td>
                                    <td v-for="data in comparisonData" :key="data.symbol">${{data.endBalance.toFixed(2)}}</td>
                                </tr>
                                <tr class="border-b">
                                    <th class="py-3 pl-2">Change</th>
                                    <td>{{((TotalEndBalance() - TotalInvestment()) / (TotalInvestment() / 100)).toFixed(2)}}%</td>
                                    <td v-for="data in comparisonData" :key="data.symbol">{{((data.endBalance - TotalInvestment()) / (TotalInvestment() / 100)).toFixed(2)}}%</td>
                                </tr>
                            </table>

                        </td>
                    </tr>
                </table>
            </div>            
        </div>
        <div class="xl:w-1/6 w-0"></div>
    </div>
</div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
      data () {
        return {
        newPerson: '',
        newInvestment: 0,
        peopleData: [],
        months: 12,
        years: 3,
        timeframe: 'months',
        personNotFound: false,
        showPercentage: true,
        showChange: true,
        showTotal: true,
        investmentMethod: 'start',
        shareId: '',
        generatingShareLink: false,
        latestBlogArticles: [],
        comparisonData: [],
        newSymbol: '' 
      }},

    created: function () {
        var urlParams = new URLSearchParams(window.location.search);
        
        if (urlParams.has('portfolio')) {
            axios
                .get('api/portfolios/' + urlParams.get('portfolio'))
                .then(response => {
                    this.timeframe = response.data.timeFrame;

                    if (this.timeFrame === 'months') {
                        this.months = response.data.timeLength;
                    }
                    else {
                        this.years = response.data.timeLength;
                    }

                    for (var i = 0; i < response.data.copies.length; i++) {
                        this.GetPerson(response.data.copies[i].investment, response.data.copies[i].investmentMethod, response.data.copies[i].username)
                    }
                });

        } else {
            axios
                .get('api/users/jaynemesis/histories')
                .then(response => {
                    this.peopleData.push({
                        history: response.data.history,
                        username: response.data.profile.userName,
                        investment: 500,
                        endBalance: 500,
                        imageUrl: '/api/users/photo/' + response.data.profile.userName + '.jpg',
                        investmentMethod: this.investmentMethod,
                        assetHistory: response.data.assetHistory
                    });
                    this.personNotFound = false;
                });

            axios
                .get('api/users/Wesl3y/histories')
                .then(response => {
                    this.peopleData.push({
                        history: response.data.history,
                        username: response.data.profile.userName,
                        investment: 500,
                        endBalance: 500,
                        imageUrl: '/api/users/photo/' + response.data.profile.userName + '.jpg',
                        investmentMethod: this.investmentMethod,
                        assetHistory: response.data.assetHistory
                    });
                    this.personNotFound = false;
                });

            this.AddComparisonSymbol('SPY');
            this.AddComparisonSymbol('QQQ');
        }
    },

    computed: {
        IsMonths: function () {
            return this.timeframe === 'months';
        },

        IsYears: function () {
            return this.timeframe === 'years';
        }
    },

    methods: {
        GetPerson: function (investment, investmentMethod, username) {
            axios
                .get('api/users/' + username + '/histories')
                .then(historyResponse => {
                    this.peopleData.push({
                        history: historyResponse.data.history,
                        username: historyResponse.data.profile.userName,
                        investment: investment,
                        endBalance: investment,
                        imageUrl: '/api/users/photo/' + historyResponse.data.profile.userName + '.jpg',
                        investmentMethod: investmentMethod,
                        assetHistory: historyResponse.data.assetHistory
                    });
                });
        },

        AddPerson: function () {
            if (!this.AlreadyHasUser(this.newPerson)) {
                axios
                    .get('api/users/' + this.newPerson + '/histories')
                    .then(response => {
                        this.peopleData.push({
                            history: response.data.history,
                            username: response.data.profile.userName,
                            investment: this.newInvestment,
                            endBalance: this.newInvestment,
                            imageUrl: '/api/users/photo/' + response.data.profile.userName + '.jpg',
                            investmentMethod: this.investmentMethod,
                            assetHistory: response.data.assetHistory
                        });
                        this.newPerson = '';
                        this.personNotFound = false;
                    })
                    .catch(error => {
                        this.personNotFound = true;
                        console.log(error);
                    });
            }
        },

        AlreadyHasUser: function (username) {
            for (var i = 0; i < this.peopleData.length; i++) {
                if (this.peopleData[i].username === username) {
                    return true;
                }
            }

            return false;
        },

        GetLatestData: function (data, count, frontToBack) {
            var newData = [];

            if (frontToBack) {
                for (var i = 0; i < data.length && i < count; i++) {
                    newData.push(data[i]);
                }
            } else {
                for (i = data.length - 1; i >= 0 && i >= data.length - count; i--) {
                    newData.push(data[i]);
                }
            }

            if (count > data.length) {
                for (i = data.length; i < count; i++) {
                    newData.push({ gain: 0 });
                }
            }

            return newData.reverse();
        },

        GetDataIndexForUsername: function (username) {
            for (var i = 0; i < this.peopleData.length; i++) {
                if (this.peopleData[i].username === username) {
                    return i;
                }
            }

            return null;
        },

        GetDataIndexForSymbol: function (symbol) {
            for (var i = 0; i < this.comparisonData.length; i++) {
                if (this.comparisonData[i].symbol === symbol) {
                    return i;
                }
            }

            return null;
        },

        WorkOutUserInvestments: function (id) {
            var balances = [];
            var dataIndex;
            var data;

            dataIndex = this.GetDataIndexForUsername(id);

            if (this.IsMonths) {
                data = this.GetLatestData(this.peopleData[dataIndex].history.monthly, this.months, false);
            }
            else {
                data = this.GetLatestData(this.peopleData[dataIndex].history.yearly, this.years, false)
            }

            var balance = this.peopleData[dataIndex].investment;

            if (this.IsMonths) {
                for (var i = 0; i < this.months; i++) {
                    var changeMonths = 0;

                    if (i !== 0 && this.peopleData[dataIndex].investmentMethod === 'pertime') {
                        balance = balance + this.peopleData[dataIndex].investment;
                    }

                    if (data[i].gain !== 0) {
                        changeMonths = (data[i].gain * (balance / 100));
                        balance = balance + changeMonths;
                    }

                    balances.push({ balance: balance, gain: data[i].gain, change: changeMonths });
                }
            }
            else {
                for (i = 0; i < this.years; i++) {
                    var changeYears = 0;

                    if (data[i].gain !== 0) {
                        changeYears = (data[i].gain * (balance / 100));
                        balance = balance + changeYears;
                    }

                    balances.push({ balance: balance, gain: data[i].gain, change: changeYears });
                }
            }

            this.peopleData[dataIndex].endBalance = balance;

            return balances;
        },

        TotalInvestment: function () {
            var investment = 0;

            for (var i = 0; i < this.peopleData.length; i++) {
                if (this.peopleData[i].investmentMethod === 'start') {
                    investment = investment + this.peopleData[i].investment;
                }
                else if (this.peopleData[i].investmentMethod === 'pertime' && this.timeframe === 'months') {
                    investment = investment + (this.months * this.peopleData[i].investment);
                }
                else {
                    investment = investment + (this.years * this.peopleData[i].investment);
                }
            }

            return investment;
        },

        TotalEndBalance: function () {
            var balance = 0;

            for (var i = 0; i < this.peopleData.length; i++) {
                balance = balance + this.peopleData[i].endBalance;
            }

            return balance;
        },

        GetMonths: function () {
            var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            var months = [];
            var start = new Date(Date.now()).getMonth();

            for (var i = 0; i < this.months; i++) {
                months.push(monthNames[start]);

                if (start === 0) {
                    start = 11;
                }
                else {
                    start--;
                }
            }

            return months.reverse();
        },

        SetTimeFrame: function (timeframe) {
            this.timeframe = timeframe;
        },

        WorkOutCombinedInvestments: function () {
            var investments = [];
            var combined = [];
            var originalInvestment = 0;
            var increasePerTimeFrame = 0;

            for (var people = 0; people < this.peopleData.length; people++) {
                investments.push(this.WorkOutUserInvestments(this.peopleData[people].username));

                originalInvestment = originalInvestment + this.peopleData[people].investment;

                if (this.peopleData[people].investmentMethod !== 'start') {
                    increasePerTimeFrame = increasePerTimeFrame + this.peopleData[people].investment;
                }
            }

            var previousBalance = originalInvestment;

            var count = 0;

            if (this.IsMonths) {
                count = this.months;
            }
            else {
                count = this.years;
            }

            for (var x = 0; x < count; x++) {
                var balance = 0;

                for (var y = 0; y < investments.length; y++) {
                    balance = balance + investments[y][x].balance;
                }

                var gain = 0;

                if (x === 0) {
                    gain = (balance - (previousBalance)) / ((previousBalance) / 100)
                }
                else {
                    gain = (balance - (previousBalance + increasePerTimeFrame)) / ((previousBalance + increasePerTimeFrame) / 100);
                }

                var change = 0;

                if (x === 0) {
                    change = balance - previousBalance;
                } else {
                    change = (balance - (previousBalance + increasePerTimeFrame));
                }

                combined.push({ balance: balance, gain: gain, change: change });

                previousBalance = balance;
            }

            return combined;
        },

        WorkOutInvestmentPercentage: function (username) {
            var dataIndex = this.GetDataIndexForUsername(username);

            if (this.peopleData[dataIndex].investmentMethod === 'pertime') {

                if (this.timeframe === 'months') {
                    return (this.peopleData[dataIndex].investment * this.months / (this.TotalInvestment() / 100)).toFixed(2)
                } else {
                    return (this.peopleData[dataIndex].investment * this.years / (this.TotalInvestment() / 100)).toFixed(2)
                }

            } else {
                return (this.peopleData[dataIndex].investment / (this.TotalInvestment() / 100)).toFixed(2)
            }
        },

        WorkOutBestReturn: function () {
            var combined = this.WorkOutCombinedInvestments();
            var best = -1000;

            for (var x = 0; x < combined.length; x++) {
                if (combined[x].gain > best) {
                    best = combined[x].gain;
                }
            }

            return best;
        },

        WorkOutWorstReturn: function () {
            var combined = this.WorkOutCombinedInvestments();
            var worst = 1000;

            for (var x = 0; x < combined.length; x++) {
                if (combined[x].gain < worst) {
                    worst = combined[x].gain;
                }
            }

            return worst;
        },

        WorkOutAverageReturn: function () {
            var combined = this.WorkOutCombinedInvestments();
            var combinedGain = 0;

            for (var x = 0; x < combined.length; x++) {
                combinedGain = combinedGain + combined[x].gain
            }

            return combinedGain / combined.length;
        },

        RemovePerson: function (username) {
            this.peopleData.splice(this.GetDataIndexForUsername(username), 1);
        },

        GetYears: function () {
            var start = new Date().getFullYear();
            var yearsList = [];

            for (var x = 0; x < this.years; x++) {
                yearsList.push(start - x);
            }

            return yearsList.reverse();
        },

        SavePortfolio: function () {
            var portfolio;

            this.shareId = '';
            this.generatingShareLink = true;

            if (this.timeframe === 'months') {
                portfolio = {
                    Copies: this.peopleData,
                    TimeFrame: 'months',
                    TimeLength: this.months
                }
            } else {
                portfolio = {
                    Copies: this.peopleData,
                    TimeFrame: 'years',
                    TimeLength: this.months
                }
            }

            axios
                .post('api/portfolios', portfolio)
                .then(response => {
                    this.shareId = response.data;
                    this.generatingShareLink = false;
                })
                .catch(error => {
                    this.personNotFound = true;
                    console.log(error);
                });
        },

        GetUniqueInstrumentsTraded: function (username) {
            var dataIndex = this.GetDataIndexForUsername(username);

            var instruments = [];

            for (var index = 0; index < this.peopleData.length; index++) {
                if (index !== dataIndex) {
                    for (var insIndex = 0; insIndex < this.peopleData[index].assetHistory.assets.length; insIndex++) {
                        instruments.push(this.peopleData[index].assetHistory.assets[insIndex].instrumentId);
                    }
                }
            }

            var notUnique = 0;

            for (var i = 0; i < this.peopleData[dataIndex].assetHistory.assets.length; i++) {
                for (var j = 0; j < instruments.length; j++) {
                    if (instruments[j] === this.peopleData[dataIndex].assetHistory.assets[i].instrumentId) {
                        notUnique++;
                        break;
                    }
                }
            }

            return (100 / this.peopleData[dataIndex].assetHistory.assets.length) * (this.peopleData[dataIndex].assetHistory.assets.length - notUnique);
        },

        AddSymbol: function () {
            if (this.newSymbol.length > 0) {
                this.AddComparisonSymbol(this.newSymbol);
                this.newSymbol = '';
            }
        },

        AddComparisonSymbol: function (symbol) {
            axios
                .get('api/symbols/' + symbol)
                .then(response => {
                    this.comparisonData.push({
                        monthly: response.data.monthly,
                        symbol: symbol,
                        yearly: response.data.yearly
                    });
                });
        },

        WorkOutComparisonInvestments: function (id) {
            var balances = [];
            var dataIndex;
            var data;

            dataIndex = this.GetDataIndexForSymbol(id);

            if (this.IsMonths) {
                data = this.GetLatestData(this.comparisonData[dataIndex].monthly, this.months, true);
            }
            else {
                data = this.GetLatestData(this.comparisonData[dataIndex].yearly, this.years, true)
            }

            var balance = this.GetTotalOneTimeInvestment();
            var pertimeInvestment = this.GetTotalPertimInvestment();

            if (this.IsMonths) {
                for (i = 0; i < this.months; i++) {
                    var changeMonths = 0;

                    if (i !== 0) {
                        balance = balance + pertimeInvestment;
                    }

                    if (data[i].gain !== 0) {
                        changeMonths = (data[i].gain * (balance / 100));

                        balance = balance + changeMonths;
                    }

                    balances.push({ balance: balance, gain: data[i].gain, change: changeMonths });
                }
            }
            else {
                for (var i = 0; i < this.years; i++) {
                    var changeYears = 0;

                    if (data[i].gain !== 0) {
                        changeYears = (data[i].gain * (balance / 100));

                        balance = balance + changeYears;
                    }

                    balances.push({ balance: balance, gain: data[i].gain, change: changeYears });
                }
            }

            this.comparisonData[dataIndex].endBalance = balance;

            return balances;
        },

        GetTotalOneTimeInvestment: function () {
            var total = 0;

            for (var i = 0; i < this.peopleData.length; i++) {
                if (this.peopleData[i].investmentMethod !== 'pertime') {
                    total = total + this.peopleData[i].investment;
                }
            }

            return total;
        },

        GetTotalPertimInvestment: function () {
            var total = 0;

            for (var i = 0; i < this.peopleData.length; i++) {
                if (this.peopleData[i].investmentMethod === 'pertime') {
                    total = total + this.peopleData[i].investment;
                }
            }

            return total;

        },

        GetTableColumnCount: function () {
            if (this.IsMonths) {
                return this.months + 1;
            }
            else {
                return this.years + 1;
            }
        },

        GetAvgComparisonReturn: function (symbol) {
            var combined = this.WorkOutComparisonInvestments(symbol);

            var total = 0;

            for (var i = 0; i < combined.length; i++) {
                total = total + combined[i].gain;
            }

            return total / combined.length;
        },

        GetBestComparisonReturn: function (symbol) {
            var combined = this.WorkOutComparisonInvestments(symbol);

            var best = 0;

            for (var i = 0; i < combined.length; i++) {
                if (combined[i].gain > best) {
                    best = combined[i].gain;
                }
            }

            return best;
        },

        GetWorstComparisonReturn: function (symbol) {
            var combined = this.WorkOutComparisonInvestments(symbol);

            var worst = 0;

            for (var i = 0; i < combined.length; i++) {
                if (combined[i].gain < worst) {
                    worst = combined[i].gain;
                }
            }

            return worst;
        }

    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>